<template>
	<div class="article-box">
		<el-table :data="articles" style="width: 100%" v-loading="loading">
			<el-table-column prop="serial" label="序号" width="150" align="center">
				<template slot-scope="scope">
					{{ (currpage - 1) * pagesize + scope.$index + 1 }}
				</template>
			</el-table-column>
			<el-table-column label="标题" align="center">
        <template slot-scope="scope">
          {{ scope.row.Title.slice(0,40) }} <span v-if="scope.row.Title.length>40">...</span>
          </template>
      </el-table-column>
			<el-table-column sortable prop="InsertTime" label="发布时间" :formatter="InsertTime" align="center">
			</el-table-column>
			<el-table-column sortable prop="UpdateTime" label="审核时间" :formatter="UpdateTimes" align="center">
			</el-table-column>
			<el-table-column prop="examine" label="审核人" align="center"></el-table-column>
			<el-table-column label="置顶" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.isTop == '1'" style="color:#F56C6C">已置顶</span>
					<span v-else style="color:#67C23A">未置顶</span>
					<i class="el-icon-top iconClick" @click="setTop(scope.row)"></i>
					<i v-if="scope.row.isTop == '1'" class="el-icon-bottom iconClick" @click="cancelTop(scope.row)"
						style="color: #67C23A;"></i>
				</template>
			</el-table-column>
			<el-table-column label="标签" prop="tag" :formatter="Tags" align="center">
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="400" align="center">
				<template slot-scope="scope">
					<el-button size="mini" type="danger" plain @click="handleEdit(scope.$index, scope.row)">冻结
					</el-button>
					<el-button size="mini" type="primary" @click="handleEdit2(scope.$index, scope.row)">编辑</el-button>
					<el-button size="mini" type="primary" @click="update(scope.row)">添加点赞</el-button>
					<el-button size="mini" type="primary" @click="insert(scope.row)">添加评论</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
		<el-dialog title="增加文章阅读点赞数量" :visible.sync="dialogVisible" :before-close="close" width="50%">
			<p>增加的阅读数量：
				<el-input-number v-model="article.read" :controls="false" placeholder="请增加阅读数量(在原有基础上增加)"
					style="width: 300px;">
				</el-input-number>
			</p>
			<p>增加的点赞数量：
				<el-input-number v-model="article.good" :controls="false" placeholder="请增加点赞数量(在原有基础上增加)"
					style="width: 300px;">
				</el-input-number>
			</p>
			<span slot="footer">
				<!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
				<el-button type="primary" @click="updateTable()">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="添加文章评论" :visible.sync="dialogVisible2" :before-close="close" width="50%">
			<div style="display: flex;justify-content: space-around;">
				<div>
					<el-table :data="userData" @cell-click="cellClick"
						style="height: 150px;overflow-y: auto;cursor: pointer;">
						<el-table-column prop="GuaName" label="用户昵称" align="center" width="150"></el-table-column>
						<!-- <el-table-column prop="HeadImageUrl" label="用户头像地址" align="center"> </el-table-column> -->
						<!-- <el-table-column prop="RegisterMobile" label="用户手机号" align="center"></el-table-column> -->
					</el-table>
				</div>
				<div>
					<p>留言人的APP账号：
						<el-input v-model="article.mobile" placeholder="请输入留言人的APP账号" style="width: 20vw;"></el-input>
					</p>
					<p>留言人的APP密码：
						<el-input v-model="article.pwd" placeholder="请输入留言人的APP密码" style="width: 20vw;"></el-input>
					</p>
					<p style="line-height: 30px;">留言人的留言内容：
						<el-input type="textarea" autosize v-model="article.mes" placeholder="请输入留言人的留言内容"
							style="width: 20vw;">
						</el-input>
					</p>
				</div>
			</div>
			<span slot="footer">
				<!-- <el-button @click="dialogVisible2 = false">取 消</el-button> -->
				<el-button type="primary" @click="insertTable()">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="编辑文章" :visible.sync="dialogFormVisible" top="5vh">
			<el-form :model="article2" ref="articleForm">
				<el-form-item label="标题">
					<el-input v-model="article2.Title" autocomplete="off" style="width:500px;float:left" readonly>
					</el-input>
				</el-form-item>
				<el-form-item label="作者">
					<el-input v-model="article2.Author" autocomplete="off" style="width:500px;float:left" readonly>
					</el-input>
				</el-form-item>
				<el-form-item label="日期">
					<el-input v-model="article2.InsertTime" autocomplete="off" style="width:260px;float:left" readonly>
					</el-input>
				</el-form-item>
				<el-form-item label="内容:"></el-form-item>
				<el-form-item>
					<editor ref="editor" v-model="text" @change="change" style="overflow:auto;"></editor>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="add()">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		articleList,
		thaw,
		setReadGood,
		setMessage,
		setUserList,
		articleDetail,
		setArticleinfo,
		articleTop
	} from '@/api/adminApi.js'
	import editor from '@/views/utils/editor'
	import md5 from 'js-md5'
	export default {
		components: {
			editor
		},
		data() {
			return {
				articles: [], // 文章列表
				currpage: 1, // 初始页
				pagesize: 12, // 每页显示条目个数
				CountNum: 0, // 总条数
				loading: false, //加载框
				dialogVisible: false, //文章阅读和点赞弹框
				dialogVisible2: false, //文章评论弹框
				dialogFormVisible: false, //编辑文章弹框
				item: {}, //列表数据缓存
				article: {}, //文章阅读和点赞增加表
				userData: {}, //添加评论用户列表
				article2: {}, //编辑文章弹框内容
				text: '', // 文章内容
				textAdd: '', //提交的文章内容
				admin: '管理员'
			}
		},
		mounted() {
			this.ArticleList(1) // 初始化获取文章列表
			this.userList() // 获取添加评论用户列表
		},
		methods: {
			add() { //点击修改文章提交按钮
				let data = {
					ID: this.article2.id, //修改的id
					articleContent: this.textAdd //文章内容
				}
				setArticleinfo(data).then(res => {
					if (res.status.code == 1) {
						this.$message.success("文章修改成功！")
						this.dialogFormVisible = false
					} else {
						this.$message.error(res.status.msg)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			change(data) { //富文本回调函数，修改后的文章内容
				this.textAdd = data
			},
			handleEdit2(index, row) { // 打开文章弹窗
				this.dialogFormVisible = true
				this.article2.Title = row.Title
				this.article2.Author = row.Author
				this.article2.InsertTime = row.InsertTime
				this.article2.id = row.ID
				this.acquireArticle(row.ID)
			},
			acquireArticle(datas) { // 获取文章内容
				const data = {
					ID: datas
				}
				articleDetail(data).then(res => {
					this.$refs.editor.setHtml(res.data.ArticleContent) //调用子组件方法传文章内容
				}).catch(err => {
					console.log(err)
				})
			},
			ArticleList(currpage) { // 文章列表
				this.loading = true
				const data = {
					page: currpage, // 页数
					limit: this.pagesize, // 总数
					status: 1 // 0待审核 1已审核 2已拒绝 3已冻结 10全部
				}
				articleList(data).then(res => {
					this.articles = res.data.List // 已审核文章列表
					this.CountNum = parseInt(res.data.CountNum) // 总条数
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			setTop(item) { //设置置顶
				this.$prompt('请输入置顶等级：', '置顶', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPlaceholder: '请输入置顶等级，最低等级1001，等级越高，排名越高',
					inputValidator: function(val) { //验证函数
						if (val > 1000) {
							return true;
						} else {
							return "置顶等级最低1001，必须是数字";
						}
					}
				}).then(({
					value
				}) => {
					let data = {
						articleId: item.ID, //文章id
						sort: value, //置顶等级
					}
					articleTop(data).then(res => {
						if (res.status.code == 1) {
							this.$message.success('置顶设置成功');
							this.ArticleList(1) // 初始化获取文章列表
						} else {
							this.$message.error(res.status.msg);
						}
					})
				})
			},
			cancelTop(item) { //取消置顶
				this.$confirm('此操作将取消当前文章置顶, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						articleId: item.ID, //文章id
						sort: 1000, //置顶等级
					}
					articleTop(data).then(res => {
						if (res.status.code == 1) {
							this.$message.success('取消置顶成功');
							this.ArticleList(1) // 初始化获取文章列表
						} else {
							this.$message.error(res.status.msg);
						}
					})
				}).catch(() => {
					this.$message.info('取消操作');
				});
			},
			UpdateTimes(row, column) { // 审核时间过滤
				return row.UpdateTime.slice(0, 16)
			},
			InsertTime(row, column) { // 发布时间过滤
				return row.InsertTime.slice(0, 16)
			},
			Tags(row, column) { // 标签过滤
				var NewArr = []
				for (let i = 0; i < row.tag.length; i++) {
					NewArr.push(row.tag[i].Tags)
				}
				return NewArr.join(',')
			},
			handleEdit(index, row) { // 文章冻结
				const data = {
					ID: row.ID,
					status: 3
				} // 文章状态 ( 1解冻 3冻结 )
				thaw(data).then(res => {
					if (res.status.code == 1) {
						this.$message({
							message: '冻结成功',
							type: 'success'
						})
						this.ArticleList(this.currpage)
					} else {
						this.$message.error(res.status.msg)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			update(data) { //添加点赞按钮
				this.dialogVisible = true
				this.item = data
			},
			insert(data) { //添加评论按钮
				this.dialogVisible2 = true
				this.item = data
			},
			close(done) { //关闭弹窗清空数据
				this.article = {}
				done()
			},
			cellClick(row, column, cell, event) { //点击添加评论弹窗中的左侧用户
				this.$set(this.article, "mobile", row.RegisterMobile);
				this.$set(this.article, "pwd", '123456');
			},
			userList() { // 获取添加评论用户列表
				const data = {}
				data["page"] = 1 //	当前页数
				data["limit"] = 1000 // 每页条数
				setUserList(data).then(res => {
					this.userData = res.data.List // 添加评论用户列表
				}).catch(err => {
					console.log(err)
				})
			},
			updateTable() { //增加点赞和阅读数量
				if (!this.article.read) {
					this.$message.warning("请输入增加的阅读数量")
				} else if (!this.article.good) {
					this.$message.warning("请输入增加的点赞数量")
				} else {
					let data = {}
					data["articleId"] = this.item.ID
					data["read"] = this.article.read
					data["good"] = this.article.good
					setReadGood(data).then(res => {
						if (res.status.code == 1) {
							this.$message.success("点赞和阅读增加成功")
							this.dialogVisible = false
							this.article = {}
						} else {
							this.$message.error(res.status.msg)
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			insertTable() { //添加留言
				if (!this.article.mobile) {
					this.$message.warning("请输入留言人账号")
				} else if (!this.article.pwd) {
					this.$message.warning("请输入留言人密码")
				} else if (!this.article.mes) {
					this.$message.warning("请输入留言内容")
				} else {
					let data = {}
					data["articleId"] = this.item.ID
					data["mobile"] = this.article.mobile
					data["pwd"] = md5(this.article.pwd)
					data["mes"] = this.article.mes
					setMessage(data).then(res => {
						if (res.status.code == 1) {
							this.$message.success("评论添加成功")
							this.dialogVisible2 = false
							this.article = {}
						} else {
							this.$message.error(res.status.msg)
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			handleCurrentChange(val) { // 分页
				this.ArticleList(val)
				this.currpage = val
			}
		}
	}
</script>
<style lang="scss" scoped>
	.article-box {
		width: 100%;

		.el-pagination {
			margin-top: 20px;
		}

		.quill-editor {
			height: 300px;
			margin-bottom: 20px;
		}
	}

	.iconClick {
		color: #F56C6C;
		font-weight: bold;
		margin-left: 3px;
		cursor: pointer;
	}
</style>
